const OSS = require('ali-oss');

export function client() {
  return new OSS({
    region: "oss-cn-shanghai",
    accessKeyId: "LTAI4FhadGhczkGvFZV3fgcX",
    accessKeySecret: "QPpKqHG6K0cFlh4FQ84v119DHg2LdL",
    bucket: "xyt-bank"
})
}
